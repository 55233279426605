* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .App {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  
  .logo {
    height: 50px;
  }
  
  .section {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 10px;
    padding: 20px;
  }
  
  .section-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .footer {
    width: 100%;
    background-color: #FCFCFC;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
  }
  
  .footer h2 {
    margin-bottom: 20px;
  }
  
  .footer-buttons button {
    margin: 5px;
    padding: 10px 20px;
    border: none;
    background-color: #333;
    color: white;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .footer-buttons button:hover {
    background-color: #555;
  }
  
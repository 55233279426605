.customBlogCard:hover{
    background-color: #344051;
    box-shadow: 1px 5px 5px #73ff99;
    transition: 0.5s ease;
    img{
        scale: 1.2;
    transition: 0.5s ease;
    }
}

#postDetails>p>span {
    font-family: Blinker !important;
    color: #fff !important;
    font-size: 14px !important;
  }
  @media (min-width: 1024px){
    #postDetails>p>span {
      font-size: 18px !important;
    }
  }

img{
    border-radius: 5px;
}

#postDetails>* {
    text-align: left !important;
    font-family: Blinker !important;
    color: #fff !important;
  }
/* Responsive heading styles for #postDetails */

#postDetails > h1 {
    font-size: 2em; /* 32px default */
    font-weight: 800;
    line-height: 1.3;
    margin-bottom: 1rem;
}

#postDetails > h2 {
    font-size: 1.5em; /* 24px default */
    /* font-weight:semi-bold; */
    line-height: 1.4;
    margin-bottom: 0.8rem;
}

#postDetails > h3 {
    font-size: 1.17em; /* 20px default */
    /* font-weight: 500; */
    line-height: 1.4;
    margin-bottom: 0.7rem;
}

#postDetails > h4 {
    font-size: 1em; /* 16px default */
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 0.6rem;
}

#postDetails > h5 {
    font-size: 0.83em; /* 14px default */
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 0.5rem;
}

#postDetails > h6 {
    font-size: 0.67em; /* 12px default */
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 0.4rem;
    color: #666;
}

#postDetails > p {
    font-size: 1rem;
    line-height: 1.6rem;
    margin-bottom: 1rem;
    font-weight: normal ;
    color: #333;
}

#postDetails > span {
    font-size: 1rem;
    color: inherit;
}

/* Span inside headings and paragraphs */
#postDetails h1 span,
#postDetails h2 span,
#postDetails h3 span,
#postDetails h4 span,
#postDetails h5 span,
#postDetails h6 span,
#postDetails p span {
    font-size: inherit !important;
    font-weight: inherit !important;
    color: inherit !important;
}

#postDetails h1 span strong,
#postDetails h2 span strong,
#postDetails h3 span strong,
#postDetails h4 span strong,
#postDetails h5 span strong,
#postDetails h6 span strong,
#postDetails p span strong {
    font-size: inherit !important;
    font-weight: inherit !important;
    color: inherit !important;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    #postDetails > h1 { font-size: 1.75em; }
    #postDetails > h2 { font-size: 1.5em; }
    #postDetails > h3 { font-size: 1.25em; }
    #postDetails > h4 { font-size: 1.1em; }
    #postDetails > h5 { font-size: 1em; }
    #postDetails > h6 { font-size: 0.85em; }
    #postDetails > p { font-size: 0.95rem; }
    #postDetails > span { font-size: 0.95rem; }
}

@media (max-width: 768px) {
    #postDetails > h1 { font-size: 1.5em; }
    #postDetails > h2 { font-size: 1.25em; }
    #postDetails > h3 { font-size: 1.1em; }
    #postDetails > h4 { font-size: 1em; }
    #postDetails > h5 { font-size: 0.9em; }
    #postDetails > h6 { font-size: 0.75em; }
    #postDetails > p { font-size: 0.9rem; }
    #postDetails > span { font-size: 0.9rem; }
}

@media (max-width: 480px) {
    #postDetails > h1 { font-size: 1.25em; }
    #postDetails > h2 { font-size: 1.1em; }
    #postDetails > h3 { font-size: 1em; }
    #postDetails > h4 { font-size: 0.9em; }
    #postDetails > h5 { font-size: 0.85em; }
    #postDetails > h6 { font-size: 0.75em; }
    #postDetails > p { font-size: 0.85rem; }
    #postDetails > span { font-size: 0.85rem; }
}

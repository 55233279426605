@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

:root {
    --primary-color: #185ee0;
    --secondary-color: #e6eef9;
}

*,
*:after,
*:before {
    box-sizing: border-box;
}

body {
    font-family: "Inter", sans-serif;
    background-color: rgba(230, 238, 249, 0.5);
}
@media screen and (max-width:991px) {
    
}

.container-tab {
    width: 100%;
    height: auto;
    z-index: 50;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tabs {
    display: flex;
    position: relative;
    background-color: #637083;
    box-shadow: 0px 5px 12px 0px #6370838C;
    padding: 7px;
    border-radius: 99px;
}

.tab.active-tab {
    color: #000;
}

.tab {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 130px;
    font-size: 1.25rem;
    font-weight: 500;
    border-radius: 99px;
    cursor: pointer;
    transition: color 0.15s ease-in;
    z-index: 100;
}

.glider {
    position: absolute;
    display: flex;
    height: 40px;
    width: 130px;
    background-color: var(--secondary-color);
    z-index: 1;
    border-radius: 99px;
    transition: transform 0.25s ease-out;
    color: #000;
    box-shadow: 0px 2px 2px 0px #0000003B;
}

@media screen and (min-width:500px) and (max-width:992px) {
    .tabs {
        margin-left: 150px !important;
    }
}

@media screen and (max-width:430px) {
    .tabs {
        margin-left: 420px;
        width: auto;
    }
}



  
  
.navbar {
  text-decoration: none;
  color: #717389 !important;
  display: block;
}
.active {
  text-decoration: none;
  color:#10F9B1;
}

.navbar:hover{
  color: #10F9B1 !important;
  
}

#services-menu.MuiMenu-paper{
  background-color: rgba(173, 168, 168, 0.5);
}
#services-menu.MuiMenu-paper:hover{
  background-color: rgba(255, 255, 255, 0.5);
}

#services-button p:hover {
  color: #10F9B1 !important;
 
}
#services-button span:hover {
  color: #10F9B1 !important;

}
#services-button:hover{
  background-color: transparent !important;
}

/* src/components/FloatingButton.css */
.floating-button {
    position: fixed;
    top: 90% !important;
    left: 95%;
    z-index: 1000;
  }
  
  .modal-box {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 546px;
    background-color: white;
    box-shadow: 24px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    text-align: center;
    border: 1px solid #10f9b1;
  }
  
/* .imageCss:hover{
    transition: filter 0.5s ease-in-out !important;
   
    background-color: red !important;
} */

.coverCase{
    
}


.imgFocus {
    transition: filter 0.5s ease-in-out !important;
    filter: blur(0.2px) !important;
    
    /* transition: 0.5s all !important; */
}

.btnFocus{
    background-color: green !important;
    transition: filter 0.5s ease-in-out !important;
    /* display: block !important; */
    z-index: 100;
    opacity: 0.5 !important;
}


button{
    font-family: Blinker !important;
    border-radius: 12px !important;
    transition: filter 0.5s ease-in-out !important;
}

.box{
    background-color: transparent !important;
    top: 15px;
    z-index:20;
    transition: filter 0.5s ease-in-out !important;
}
.box::before{
    background-color: transparent !important;
    top: 15px;
    z-index:20;
    transition: 0.5s all !important; 
}
/* .txt{
    display: inline !important;
    z-index: 20;
    background-color: transparent !important;
    transition: filter 0.5s ease-in-out, transform 5s ease-in-out;
} */
.pagination-dots {
    position: fixed;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: gray;
    cursor: pointer;
  }
  
  .dot.active {
    background-color: #10f9b1;
  }
  
